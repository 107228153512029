<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button></ion-back-button>
        </ion-buttons>
        <ion-title>Firma Operatore</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <!-- <ion-header collapse="condense">
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-back-button></ion-back-button>
          </ion-buttons>
          <ion-title size="large">Firma Operatore</ion-title>
        </ion-toolbar>
      </ion-header> -->

      <div
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
        "
      >
        <div
          class="buttons_container"
          style="
            height: 100%;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            flex-direction: column;
          "
        >
          <ion-button
            color="danger"
            size="default"
            expand="full"
            @click="clearCanvas"
            style="transform: rotate(90deg)"
          >
            Elimina firma
          </ion-button>
          <ion-button
            color="success"
            size="default"
            expand="full"
            @click="salvaFirmaOperatore"
            style="transform: rotate(90deg)"
          >
            Salva firma
          </ion-button>
        </div>
        <div style="display: flex; justify-content: center">
          <canvas
            style="border: 2px solid #d4d4d4"
            ref="canvas"
            @touchstart="mStart"
            @touchmove="mMove"
            @touchend="mEnd"
          >
          </canvas>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonPage,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonButton,
} from "@ionic/vue";
import { useRoute, useRouter } from "vue-router";
import { reactive, ref, onMounted } from "vue";

import apiInterventi from "../services/interventi";
import { openToast } from "../services/toast";

export default {
  name: "FirmaOperatore",
  components: {
    IonPage,
    IonContent,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonTitle,
    IonButton,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const interventoID = route.params.id;
    const hasSigned = ref(false); //check if operatore has signed, otherwise don't save

    const canvas = ref<HTMLCanvasElement | null>(null);
    const point = reactive({
      x: 0,
      y: 0,
    });
    const moving = ref(false);
    const ctx = ref(null);
    //TOUCH START
    function mStart(e) {
      //console.log(e);
      hasSigned.value = true;

      const x = e.touches[0].clientX - e.target.offsetLeft;
      const y = e.touches[0].clientY - e.target.offsetTop; // get the coordinates of the touch point on the canvas
      point.x = x;
      point.y = y;
      ctx.value.beginPath();
      moving.value = true;
    }
    //SLIDE START
    function mMove(e) {
      if (moving.value) {
        const x = e.touches[0].clientX - e.target.offsetLeft;
        const y = e.touches[0].clientY - e.target.offsetTop; // get the coordinates of the touch point on the canvas
        ctx.value.moveTo(point.x, point.y); // move the path to the specified point in the canvas without creating a line (starting point)
        ctx.value.lineTo(x, y); // add a new point, and then create a line from the point to the last specified point in the canvas, without creating a line
        ctx.value.stroke(); // draw
        point.x = x;
        point.y = y; // reset the point coordinate to the previous coordinate
      }
    }
    //SLIDE END
    function mEnd() {
      if (moving.value) {
        ctx.value.closePath(); // stop drawing
        moving.value = false; // turn off the draw switch
      }
    }
    function clearCanvas() {
      ctx.value = canvas.value.getContext("2d");
      const dataURL = canvas.value.toDataURL();
      ctx.value.clearRect(0, 0, canvas.value.width, canvas.value.height);
      hasSigned.value = false;
      // console.log(hasSigned.value);
    }
    function getCanvas() {
      const dataURL = canvas.value.toDataURL();
      //console.log(dataURL);
      return dataURL;
    }

    function salvaFirmaOperatore() {
      if (hasSigned.value) {
        //Get canvas content and remove 'data:image/png;base64,' fromm string
        const sign = getCanvas().replace("data:image/png;base64,", "");

        apiInterventi
          .saveOperatorSign(sign, interventoID as string)
          .then((response) => {
            //console.log(response);
            router.back();
            openToast("Firma operatore salvata correttamente", "success");
          })
          .catch((error) => {
            // handle error
            console.error(error);
            openToast("Errore durante il salvataggio della firma", "danger");
          })
          .finally(() => {
            //loading.value = false;
            //console.log("fine");
          });
      } else {
        openToast("Non puoi salvare una firma vuota", "danger");
      }
    }

    onMounted(() => {
      canvas.value.width = 200;
      canvas.value.height = 500;
      /********************************************************* */
      ctx.value = canvas.value.getContext("2d");
      ctx.value.lineWidth = 1;
      ctx.value.lineCap = "round";
    });

    return {
      salvaFirmaOperatore,
      canvas,
      //touch
      mStart,
      mMove,
      mEnd,
      clearCanvas,
      getCanvas,
    };
  },
};
</script>

<style scoped>
.canvas {
  margin: 0 auto;
  margin-top: 25px;
  border: 1px solid gray;
}

.btn_sign {
  width: 80%;
  margin: 0 auto;
}

.btn {
  transform: rotate(90deg);
}
</style>